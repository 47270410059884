// extracted by mini-css-extract-plugin
export var insuranceBenefitsSection = "B_dT";
export var insuranceBusinessesSection = "B_dR";
export var insuranceCaseStudySection = "B_dW";
export var insuranceClientQuotesSection = "B_d3";
export var insuranceDevExpertiseSection = "B_d0";
export var insuranceDevServicesIconSection = "B_dQ";
export var insuranceOurAchievementsSection = "B_d2";
export var insuranceProcessSection = "B_dS";
export var insuranceRequirementsSection = "B_dX";
export var insuranceSkilledSection = "B_d1";
export var insuranceSuccessStoriesSection = "B_dY";
export var insuranceTechStackSection = "B_dV";
export var insuranceWhyHireSection = "B_dZ";